// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-section-template-tsx": () => import("./../src/templates/SectionTemplate.tsx" /* webpackChunkName: "component---src-templates-section-template-tsx" */),
  "component---src-templates-product-family-template-tsx": () => import("./../src/templates/ProductFamilyTemplate.tsx" /* webpackChunkName: "component---src-templates-product-family-template-tsx" */),
  "component---src-templates-news-article-template-tsx": () => import("./../src/templates/NewsArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-news-article-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

